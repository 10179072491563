import { useEffect, useState } from 'react';
import { Price } from '../../common-types';
import './NumberInput.css';

export default function NumberInput(props: {
    label: string,
    amount: number,
    unit?: string,
    min?: number,
    onAmountChange: (newAmount: number) => void
}) {
    const [currentAmount, setAmount] = useState(props.amount);
    useEffect(() => {
        setAmount(props.amount);
    }, [props.amount]);
    return (
        <div className='number-input'>
            <div className='label'><label htmlFor='ninput'>{props.label}</label></div>
            <div className='input'>
                <input id='pinput' style={{
                    "--size": `${isNaN(currentAmount) ? 2 : currentAmount.toString().trim().length}ch`
                } as any} type='number' step={1} value={currentAmount} placeholder='0' onChange={(e) => {
                    let newNumber = parseInt(e.target.value);
                    if(typeof props.min == "number" && newNumber < props.min) newNumber = NaN;
                    setAmount(newNumber);
                    if(!isNaN(newNumber)) {
                        props.onAmountChange(newNumber);
                    };
                }}/>
                {props.unit && <div className='unit'><label htmlFor='ninput'>{props.unit}</label></div>}
            </div>
        </div>
    )
}