import { TicketTourData, TicketTransferData, TicketType } from "../../common-types";
import { ModeratorTicket } from "../../moderator-types";
import "./TicketInfo.css";

export default function(props: {
    ticketEntry: ModeratorTicket
}) {
    const ticketEntry = props.ticketEntry;
    const ticket = ticketEntry.ticket;
    const ticketType = ticket.type;
    const typeName = ticketType === "tour" ? "Tur" : "Transfer";
    let info: { key: string, label: string, value: string }[] = [];
    if(ticketType === TicketType.Tour) {
        const data = ticket.data as TicketTourData;
        const tour = data.tour;
        const selectedExtras = data.selectedExtras.map(e => tour.extras.find(ex => ex.id === e)).filter(e => !!e);
        const childPassengers = data.passengers.child;
        const adultPassengers = data.passengers.adult;
        const passengerText = `${adultPassengers} Yetişkin${childPassengers > 0 ? `, ${childPassengers} Çocuk` : ""}`;
        info = [
            {
                key: "tourName",
                label: "Seçilen Tur",
                value: data.tour.title
            },
            {
                key: "tourDate",
                label: "Tarih",
                value: new Date(data.date).toLocaleDateString()
            },
            {
                key: "passengerCount",
                label: "Yolcu Sayısı",
                value: passengerText
            },
            {
                key: "selectedExtras",
                label: "Seçilen Ekstralar",
                value: selectedExtras.length > 0 ? selectedExtras.map(e => e.label).join(", ") : "Ekstra seçilmedi"
            }
        ]
    }
    else if(ticketType === TicketType.Transfer) {
        const data = ticket.data as TicketTransferData;
        info = [
            {
                key: "from",
                label: "Nereden",
                value: data.from.name
            },
            {
                key: "to",
                label: "Nereye",
                value: data.to.name
            },
            {
                key: "isRoundTrip",
                label: "Gidiş Dönüş Tipi",
                value: data.isRoundTrip ? "Gidiş Dönüş" : "Tek Yön"
            },
            {
                key: "date",
                label: "Tarih",
                value: new Date(data.departureDate).toLocaleDateString()
            },
            {
                key: "passengerCount",
                label: "Yolcu Sayısı",
                value: data.passengerCount?.toString()
            }
        ]
    }
    const data = ticket.data;
    info.push(
        {
            key: "price",
            label: "Fiyat",
            value: (!!data.price.discountedAmount ? `${data.price.discountedAmount} ${data.price.currency} (İndirim uygulandı, indirimsiz fiyat: ${data.price.amount} ${data.price.currency})` : `${data.price.amount} ${data.price.currency}`)
        });
    return (<div className="ticket-info">
        <div className="ticket-info-title">{typeName} Bilgileri</div>
        <div className="ticket-info-properties">
            {info.map((i) => {
                return (
                    <div key={i.key} className="ticket-info-property">
                        <span className="ticket-info-label">{i.label}</span>
                        <span className="ticket-info-value">{i.value}</span>
                    </div>
                )
            })}
        </div>
    </div>);
}