import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import { ApiErrorResult } from "../../common-types";
import { Discount as DiscountType, Discounts, DiscountTarget } from "../../moderator-types";
import Discount from "../../components/discount/Discount";
import useCommon from "../../hooks/useCommon";
import "./Discounts.css";

export default function() {
    const api = useApi();
    const common = useCommon();
    const queryResult = useQuery({
        queryKey: ["discounts"],
        queryFn: async () => {
            return await api.getDiscounts();
        }
    });
    const asError = queryResult.data as ApiErrorResult;
    const isError = asError?.isError;
    const discounts = queryResult?.data as Discounts;
    const saveDiscountHandler = (discount: DiscountType) => {
        common.setLoading(true);
        api.applyDiscount(discount).finally(() => {
            queryResult.refetch();
            common.setLoading(false);
        })
    }
    const disableDiscountHandler = (target: DiscountTarget) => {
        common.setLoading(true);
        api.removeDiscount(target).finally(() => {
            queryResult.refetch();
            common.setLoading(false);
        })
    }
    return (
        <div className="discounts-container">
            {(!isError && queryResult.isFetched) && <>
                <div className="column">
                    <Discount
                        target={DiscountTarget.TourAll}
                        label="Genel Tur İndirimi"
                        discountAmount={discounts?.[DiscountTarget.TourAll]?.percentage}
                        onSave={saveDiscountHandler}
                        onDisable={disableDiscountHandler}
                    />
                    <Discount
                        target={DiscountTarget.TourChildrenAll}
                        label="Tur Çocuk İndirimi"
                        discountAmount={discounts?.[DiscountTarget.TourChildrenAll]?.percentage}
                        onSave={saveDiscountHandler}
                        onDisable={disableDiscountHandler}
                    />
                </div>
                <div className="column">
                    <Discount
                        target={DiscountTarget.TransferAll}
                        discountAmount={discounts?.[DiscountTarget.TransferAll]?.percentage}
                        label="Genel Transfer İndirimi"
                        onSave={saveDiscountHandler}
                        onDisable={disableDiscountHandler}
                    />
                    <Discount
                        target={DiscountTarget.TransferRoundTrip}
                        discountAmount={discounts?.[DiscountTarget.TransferRoundTrip]?.percentage}
                        label="Round Trip Transfer İndirimi"
                        onSave={saveDiscountHandler}
                        onDisable={disableDiscountHandler}
                    />
                </div>
            </>}
        </div>
    )
}