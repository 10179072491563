import { Tour, DiscountType } from '../../common-types';
import Discount from '../../components/discount/Discount';
import InputGroup from '../../components/input-group/InputGroup';
import PriceInput from '../../components/price-input/PriceInput';
import SelectionCard from '../../components/selection-card/SelectionCard';
import Toggle from '../../components/toggle/Toggle';
import { DiscountTarget } from '../../moderator-types';
import './TourGeneralPanel.css';
import TourImageInput from './TourImageInput';
import TourInputGroup from './TourInputGroup';

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    const {getCloned, setTour, tour} = props;
    const handleTitleChange = (newTitle: string) => {
        const newTour = getCloned();
        newTour.title = newTitle;
        setTour(newTour);
    }
    const handleDescriptionChange = (newDescription: string) => {
        const newTour = getCloned();
        newTour.description = newDescription;
        setTour(newTour);
    }
    const handlePlaceChange = (newPlace: string) => {
        const newTour = getCloned();
        newTour.place = newPlace;
        setTour(newTour);
    }
    const handleDurationChange = (newDuration: string) => {
        const newTour = getCloned();
        newTour.duration = newDuration;
        setTour(newTour);
    }
    return (
        <div className='tour-general-panel'>
            <TourInputGroup>
            <label htmlFor='tour-title'>Tur Başlığı</label>
            <input
                id='tour-title'
                type='text'
                value={tour.title}
                placeholder='Tur Başlığı'
                onChange={(e) => handleTitleChange(e.target.value)}
            />
            </TourInputGroup>
            <TourInputGroup>
            <label htmlFor='tour-description'>Tur Açıklaması</label>
            <textarea
                id='tour-description'
                onChange={(e) => handleDescriptionChange(e.target.value)}
                value={tour.description}
            >
            </textarea>
            </TourInputGroup>
            <TourInputGroup>
                <label htmlFor='tour-place'>Tur Yeri</label>
                <input
                    type='text'
                    id='tour-place'
                    onChange={(e) => {
                        handlePlaceChange(e.target.value)
                    }}
                    value={tour.place}
                />
            </TourInputGroup>
            <TourInputGroup>
                <label htmlFor='tour-duration'>Tur Uzunluğu</label>
                <input
                    type='text'
                    id='tour-duration'
                    onChange={(e) => {
                        handleDurationChange(e.target.value)
                    }}
                    value={tour.duration ?? ""}
                />
            </TourInputGroup>
            <TourInputGroup isHorizontal={true}>
                <label htmlFor='tour-thumbnail'>Tur Resmi</label>
                <TourImageInput imageUrl={tour.thumbnail} onImageUploaded={(url) => {
                    const cloned = getCloned();
                    cloned.thumbnail = url;
                    setTour(cloned);
                }} />
            </TourInputGroup>
            <div className='hline'></div>
            <PriceInput
                price={tour.price}
                label='Tur Fiyatı'
                onAmountChange={(newAmount) => {
                    const newTour = getCloned();
                    newTour.price.amount = newAmount;
                    setTour(newTour);
                }}
            />
            <div className='hline'></div>
            {
                tour.discountType === DiscountType.Single && (
                    <>
                    <InputGroup title='Bu Tura Özel İndirimler'>
                        <Discount
                            label='Genel İndirim'
                            description='Yetişkin fiyatına ve ekstralara uygulanır.'
                            onChange={(percentage) => {
                                const newTour = getCloned();
                                newTour.singleDiscounts.adult = percentage;
                                setTour(newTour);
                            }}
                            target={DiscountTarget.TourAll}
                            onDisable={() => {
                                const newTour = getCloned();
                                delete newTour.singleDiscounts.adult;
                                setTour(newTour);
                            }}
                            discountAmount={tour.singleDiscounts.adult ?? 0}
                        />
                        <Discount
                            label='Çocuk İndirimi'
                            description='Genel indirimin üzerine çocuk indirimi uygulanır.'
                            onChange={(percentage) => {
                                const newTour = getCloned();
                                newTour.singleDiscounts.child = percentage;
                                setTour(newTour);
                            }}
                            target={DiscountTarget.TourAll}
                            onDisable={() => {
                                const newTour = getCloned();
                                delete newTour.singleDiscounts.child;
                                setTour(newTour);
                            }}
                            discountAmount={tour.singleDiscounts.child ?? 0}
                        />
                    </InputGroup>
                    <div className='hline'></div>
                    </>
                )
            }
            <InputGroup title='İndirim Uygulama Tipi'>
            <SelectionCard
                items={{
                    [DiscountType.None]: {
                        title: "İndirim Uygulama",
                        description: "Çocuk indirimi dahil hiçbir indirim uygulanmaz, genel tur indirimi dahil."
                    },
                    [DiscountType.Single]: {
                        title: "Bu Tura Özel İndirim",
                        description: "Bu tur için seçilen indirim uygulanır."
                    },
                    [DiscountType.UseGeneral]: {
                        title: "Genel İndirim",
                        description: "Bu tur için \"Fiyatlar\" bölümünde belirlenen genel indirim uygulanır."
                    }
                }}
                selectedItem={tour.discountType}
                onSelectedItemChange={(newItem) => {
                    const newTour = getCloned();
                    newTour.discountType = newItem as DiscountType;
                    setTour(newTour);
                }}
            />
            </InputGroup>
        </div>
    )
}