import { TourTimetableEntry } from '../../common-types';
import './TourTimetableDay.css';
import TourTimetableEntryItem from './TourTimetableEntryItem';

export default function (props: {
    day: number,
    entries: {
        entry: TourTimetableEntry,
        index: number
    }[],
    newIndex: number,
    onDayCreation: (newTime?: number, newDescription?: string, newShowTime?: boolean) => void,
    onEntryTimeChange: (newTime: number, index: number) => void,
    onEntryDescriptionChange: (newDescription: string, index: number) => void,
    onEntryRemoval: (index: number) => void,
    onTimeShowChange: (value: boolean, index: number) => void,
    autofocusTarget?: { index: number, element: string } | null,
    isPlaceholder?: boolean
}) {
    const { day, entries } = props;
    const className = ["tour-timetable-day", props.isPlaceholder && "placeholder"].filter(t => !!t).join(" ");
    return (
        <div className={className}>
            <div className='tour-timetable-day-header'>
                <div className='tour-timetable-header-line' />
                    {day}. Gün
                <div className='tour-timetable-header-line' />
            </div>
            <div className='tour-timetable-day-entries'>
                {entries.map((entry, index) => {
                    return (
                        <TourTimetableEntryItem
                            index={entry.index}
                            key={entry.index}
                            entry={entry.entry}
                            onTimeChange={(newTime) => props.onEntryTimeChange(newTime, entry.index)}
                            onDescriptionChange={(newDescription) => props.onEntryDescriptionChange(newDescription, entry.index)}
                            onTimeShowChange={(value) => props.onTimeShowChange(value, entry.index)}
                            autofocus={props.autofocusTarget?.index === entry.index}
                            autofocusTarget={props.autofocusTarget?.element}
                            onRemoval={() => props.onEntryRemoval(entry.index)}
                        />
                    )
                })}
                <TourTimetableEntryItem
                    key={props.newIndex}
                    entry={{
                        day,
                        description: '',
                        time: 13 * 60
                    }}
                    onTimeChange={(newTime) => {
                        props.onDayCreation(newTime);
                    }}
                    onDescriptionChange={(newDescription) => {
                        props.onDayCreation(undefined, newDescription);
                    }}
                    onTimeShowChange={() => {
                        props.onDayCreation();
                    }}
                    autofocus={false}
                    isPlaceholder={true}
                    onRemoval={() => {}}
                />
            </div>
        </div>
    )
}