export type ApiErrorResult = {
    isError: true;
    error_type?: string;
    message?: string;
}

export type GenericApiResult<DesiredResultType> = DesiredResultType | ApiErrorResult;

export type Currency = "USD" | "EUR" | "GBP";

export type Price = {
    amount: number;
    currency: Currency;
}

export type FullPrice = {
    amount: number;
    currency: Currency;
    discountedAmount?: number;
}

export type Place = {
    lat?: number;
    lon?: number;
    placeId?: string;
    displayName?: string;
    name?: string;
    info?: string;
}

export type TicketTransferData = {
    from: Place;
    to: Place;
    departureDate: string;
    returnDate?: string;
    isRoundTrip: boolean;
    price: FullPrice;
    durationSeconds: number;
    distanceMeters: number;
    passengerCount: number;
}

export type TourTimetableEntry = {
    day: number;
    time: number;
    description: string;
    showTime?: boolean;
}

export type TourDateSingle = {
    date: string;
}

export type TourDateEveryDay = {
    time: number;
}

export type TourDateEveryWeek = {
    weekday: number;
    time: number;
}

export type TourDateEveryMonth = {
    day: number;
    time: number;
}

export enum TourDateEntryType {
    Single = "single",
    EveryDay = "every_day",
    EveryWeek = "every_week",
    EveryMonth = "every_month"
}

export type TourDateEntry = {
    type: TourDateEntryType;
    exclude?: boolean;
} & (
    {
        type: TourDateEntryType.Single;
        data: TourDateSingle;
    } |
    {
        type: TourDateEntryType.EveryDay;
        data: TourDateEveryDay;
    } |
    {
        type: TourDateEntryType.EveryWeek;
        data: TourDateEveryWeek;
    } |
    {
        type: TourDateEntryType.EveryMonth;
        data: TourDateEveryMonth;
    }
)

export enum TourIncludeIcon {
    Food = "food",
    Drink = "drink",
    Alcohol = "alcohol",
    Bus = "bus",
    Boat = "boat",
    CableCar = "cable-car",
    Camera = "camera",
    Book = "book",
    Bed = "bed",
    Moon = "moon",
    Sun = "sun",
    Gift = "gift",
    MultiDay = "multi-day",
    Museum = "museum",
    Party = "party",
    Shopping = "shopping",
    Waves = "waves"
}

export type TourInclude = {
    icon: TourIncludeIcon;
    label: string;
}

export type TourExtra = {
    id: number;
    additionalPrice: FullPrice;
    label: string;
    description: string;
}

export enum TourPassengerType
{
    Adult = "adult",
    Child = "child"
}

export enum DiscountType {
    None = "none",
    UseGeneral = "use_general",
    Single = "single"
}

export type Tour = {
    id: number,
    title: string;
    description: string;
    price: FullPrice;
    details: string;
    includes: TourInclude[];
    timetable: TourTimetableEntry[];
    place: string;
    dates: TourDateEntry[];
    images: string[];
    thumbnail: string;
    duration: string;
    extras: TourExtra[];
    discountType: DiscountType;
    singleDiscounts: {[passengerType in TourPassengerType]: number};
}

export type TicketTourData = {
    tour: Omit<Tour, "price">;
    date: string;
    passengers: {[passengerType in TourPassengerType]: number};
    selectedExtras: number[];
    price: FullPrice;
}

export enum UserContactMethod {
    Email = "mail",
    WhatsApp = "wp"
}

export type User = {
    name: string;
    surname: string;
    email: string;
    phone: string;
    preferredContactMethod: UserContactMethod;
}

export enum TicketStatusType {
    Open = "open",
    Booked = "booked",
    Reached = "reached",
    Confirmed = "confirmed",
    CancelledUser = "cancelled_user",
    CancelledModerator = "cancelled_moderator",
    Finished = "finished",
}

export type TicketStatus = {
    ticketId: string;
    type: TicketStatusType;
    date: string;
    message?: string;
}

export enum TicketType {
    Transfer = "transfer",
    Tour = "tour"
}

export type Ticket = {
    id: string;
    user?: User;
    statusHistory: TicketStatus[];
    type: TicketType;
    data: TicketTransferData | TicketTourData;
    creationDate: string;
    updateDate: string;
}

export type TicketListEntry = {
    id: string;
    status: TicketStatus;
    type: TicketType;
    displayName: string;
    creationDate: string;
    updateDate: string;
    bookedDate?: string;
    price: Price;
}

export type TourListEntry = {
    id: number;
    title: string;
    description: string;
    price: FullPrice;
    place: string;
    thumbnail: string;
}

export type ApiEditResult = {
    success: boolean;
    message: string;
}

export type ApiImageUploadResult = {
    success: boolean;
    url: string;
}

export type SearchRequest = {
    fromLocation?: Place;
    toLocation?: Place;
    isRoundTrip?: boolean;
    departureDate?: string;
    returnDate?: string;
    passengerCount: number;
}

export enum SearchRequestResponseErrorType {
    InvalidRequest = "invalid_request",
    InvalidPlaceId = "invalid_place_id",
    NoDate = "no_date",
    InvalidDate = "invalid_date",
    DateTooClose = "date_too_close",
    DepartureAfterReturn = "dep_after_ret",
    ServerError = "server_error"
}

export type SearchRequestResponse = {
    ticketId: string;
}

export type OpenTicketResponse = {
    ticket: Ticket;
}

export type PlacePrediction = {
    name: string;
    info: string;
    placeId: string;
}

export type PlaceIframeResult = {
    iframeUrl: string;
}

export type BookTicketRequest = {
    ticketId: string;
    user: User;
}

export type BookTicketResponse = {
    success: true;
}

export type BookedSingleTicketRequest = {
    ticketId: string;
    mailOrPhone: string;
}

export type TicketCancelRequest = {
    ticketId: string;
    mailOrPhone: string;
}

export type CreateTourTicketRequest = {
    tourId: number;
    date: string;
    passengerCounts: {[passengerType in TourPassengerType]: number};
    selectedExtras: number[];
}

export type TourPricesResponse = {
    adult: FullPrice;
    child: FullPrice;
}

export type CalculateTourPriceRequest = {
    tourId: number;
    passengerCounts: {[passengerType in TourPassengerType]: number};
    selectedExtras: number[];
}

export type CreateTourTicketError = "TourNotFound" | "InvalidDate" | "InvalidPassengerCount" | "ServerError";