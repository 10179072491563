import { LucideLogOut } from "lucide-react";
import { useSession } from "../hooks/useSession";

export default function LogOut() {
    const session = useSession();
    return (
        <div className="log-out">
            <button onClick={() => session.clearToken()}><LucideLogOut/></button>
        </div>
    )
}