import { useQuery } from "@tanstack/react-query";
import TicketInfo from "../../components/ticket-info/TicketInfo";
import { useApi } from "../../hooks/useApi";
import useCommon from "../../hooks/useCommon";
import "./EditTicket.css";
import { ApiErrorResult } from "../../common-types";
import { ModeratorTicket } from "../../moderator-types";
import { usePanels } from "../../hooks/usePanels";
import TicketHistory from "../../components/ticket-history/TicketHistory";
import UserInfo from "../../components/user-info/UserInfo";

export default function(props: {
    ticketId: string
}) {
    const panels = usePanels();
    const ticketId = props.ticketId;
    const api = useApi();
    const common = useCommon();
    const ticketResult = useQuery({
        queryKey: [ticketId],
        queryFn: async () => {
            common.setLoading(true);
            const ticketResult = await api.getTicketDetails(ticketId);
            common.setLoading(false);
            const asErr = ticketResult as ApiErrorResult;
            if(asErr.isError)
            {
                panels.popPanel();
                return null;
            }
            const asSuccess = ticketResult as ModeratorTicket;
            return asSuccess;
        }
    });
    const onAcquire = () => {
        common.setLoading(true);
        api.requestAssignment(ticketId).finally(() => {
            ticketResult.refetch();
            common.setLoading(false);
        });
    }
    const onRelease = () => {
        common.setLoading(true);
        api.releaseAssignment(ticketId).finally(() => {
            ticketResult.refetch();
            common.setLoading(false);
        });
    }
    const ticketEntry = ticketResult.data;
    const isLoaded = ticketResult.isFetched;
    const canClickButton = isLoaded && (!ticketEntry?.assignedTo || !!ticketEntry?.assignedSelf);
    let buttonPrompt = "";
    if(canClickButton)
        buttonPrompt = ticketEntry?.assignedSelf ? "Bileti Bırak" : "Bileti Al";
    else
        buttonPrompt = `${ticketEntry?.assignedTo} bakıyor`;
    return (
        <div className="edit-ticket-container">
            <div className="ticket-user-container">
            {isLoaded && <TicketInfo ticketEntry={ticketEntry} />}
            {isLoaded && <button onClick={ticketEntry.assignedSelf ? onRelease : onAcquire} disabled={!canClickButton} className="ticket-assign-button">{buttonPrompt}</button>}
            {(isLoaded && ticketEntry?.ticket?.user) && <UserInfo user={ticketEntry.ticket.user} />}
            </div>
            {isLoaded && <TicketHistory refreshHandler={() => ticketResult.refetch()} ticket={ticketEntry} />}
        </div>
    )
}