import { useEffect, useState } from "react";
import TransferPricingEntry from "./TransferPricingEntry";
import { useQuery } from "@tanstack/react-query";
import { useApi } from "../../hooks/useApi";
import { ApiErrorResult, GenericApiResult } from "../../common-types";
import { TransferPriceApplier, TransferPriceApplierType } from "../../moderator-types";
import "./TransferPricing.css";
import useCommon from "../../hooks/useCommon";

export default function (props: {
    appliers: TransferPriceApplier[],
    onNewAppliers: (appliers: TransferPriceApplier[]) => void
}) {
    const [autoFocus, setAutoFocus] = useState<{
        index: number,
        target: string
    }>(null!);
    const transferPriceAppliers = props.appliers;
    return (
        <div className="transfer-pricing-entries-panel">
            {transferPriceAppliers.map((entry, index) => {
                return (
                    <TransferPricingEntry
                        entry={entry}
                        key={index}
                        onChange={(newEntry, changeElement) => {
                            const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                            newEntries[index] = newEntry;
                            props.onNewAppliers(newEntries);
                        }}
                        canRemove={true}
                        onRemove={() => {
                            const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                            newEntries.splice(index, 1);
                            props.onNewAppliers(newEntries);
                        }}
                        autoFocus={autoFocus && autoFocus.index === index}
                        autoFocusTarget={autoFocus && autoFocus.index === index && autoFocus.target}
                    />
                )
            })}
            <TransferPricingEntry
                entry={{
                    type: TransferPriceApplierType.Fixed,
                    vehicleId: 0 /* FIXTHIS */,
                    afterKm: 0,
                    price: 50
                }}
                key={-1}
                onChange={(newEntry, changeElementName, inputElement) => {
                    const newEntries = JSON.parse(JSON.stringify(transferPriceAppliers));
                    newEntries.push(newEntry);
                    const newAutoFocus = {
                        index: newEntries.length - 1,
                        target: changeElementName
                    };
                    inputElement.blur();
                    setAutoFocus(newAutoFocus);
                    props.onNewAppliers(newEntries);
                }}
                className="placeholder"
                canRemove={false}
                autoFocus={false}
            />
        </div>
    )
}