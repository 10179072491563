import { useQuery } from '@tanstack/react-query';
import './TransferPricingCars.css';
import { TransferPriceApplier, TransferVehicle, TransferVehiclesAndAppliers } from '../../moderator-types';
import { useState } from 'react';
import { useApi } from '../../hooks/useApi';
import useCommon from '../../hooks/useCommon';
import { ApiErrorResult, DiscountType } from '../../common-types';
import { Save } from 'lucide-react';
import TransferPricingCar from './TransferPricingCar';

export default function TransferPricingCars() {
    const [vehicles, setVehicles] = useState<TransferVehicle[]>([]);
    const [applierMap, setApplierMap] = useState<{[key: string]: TransferPriceApplier[]}>({});
    const api = useApi();
    const common = useCommon();

    const saveProcess = async (refetch?: boolean) => {
        refetch ??= true;
        const allAppliers = [];
        Object.values(applierMap).forEach(appliers => allAppliers.push(...appliers));
        await api.updateAllAppliersAndVehicles({
            vehicles,
            appliers: allAppliers
        });
        if(refetch) queryResult.refetch();
    }

    const onSave = () => {
        common.setLoading(true);
        saveProcess().finally(() => common.setLoading(false));
    }

    const onAddVehicle = () => {
        common.setLoading(true);
        const process = async () => {
            await saveProcess(false);
            await api.getNewTransferVehicle();
            queryResult.refetch();
        };
        process().finally(() => common.setLoading(false));
    }

    const onRemoveVehicle = (vehicleId: number) => {
        common.setLoading(true);
        const process = async () => {
            await api.deleteTransferVehicle(vehicleId);
            queryResult.refetch();
        }
        process().finally(() => common.setLoading(false));
    }

    const queryResult = useQuery({
        queryKey: ['transfer-pricing-cars'],
        queryFn: async () => {
            common.setLoading(true);
            const res = await api.getTransferVehiclesWithAppliers();
            const asError = res as ApiErrorResult;
            if(asError.isError)
            {
                common.setLoading(false);
                return null;
            }
            const asSuccess = res as TransferVehiclesAndAppliers;
            setVehicles(asSuccess.vehicles);
            setApplierMap(asSuccess.applierMap);
            common.setLoading(false);
            return null;
        }
    });

    return (
        <div className='transfer-pricing-cars'>
            <button className='save' onClick={onSave}>Kaydet <Save/></button>
            <div className='vehicles'>
                <TransferPricingCar
                    vehicle={{
                        id: 0,
                        maxPassengers: 0,
                        name: '',
                        discountType: DiscountType.None,
                        discountAmount: {}
                    }}
                    isPlaceholder={true}
                    onClick={onAddVehicle}
                    onAppliersChange={() => {}}
                    onVehicleChange={() => {}}
                    appliers={[]}
                    onRemove={() => {}}
                />
                {vehicles.map((vehicle, ix) => {
                    const appliers = applierMap[vehicle.id.toString()] ?? [];
                    return (
                        <TransferPricingCar
                            vehicle={vehicle}
                            appliers={appliers}
                            onAppliersChange={newAppliers => {
                                newAppliers.forEach(a => a.vehicleId = vehicle.id);
                                const newApplierMap = JSON.parse(JSON.stringify(applierMap));
                                newApplierMap[vehicle.id] = newAppliers;
                                setApplierMap(newApplierMap);
                            }}
                            onVehicleChange={newVehicle => {
                                const newVehicles = JSON.parse(JSON.stringify(vehicles));
                                newVehicles[ix] = newVehicle;
                                setVehicles(newVehicles);
                            }}
                            onRemove={() => onRemoveVehicle(vehicle.id)}
                            key={vehicle.id}
                        />
                    )
                })}
            </div>
        </div>
    )
}