import { Plus } from "lucide-react";
import { Tour } from "../../common-types";
import TourExtra from "./TourExtra";
import "./TourExtrasPanel.css";

export default function(props: {
    getCloned: () => Tour,
    setTour: (t: Tour) => void,
    tour: Tour
}) {
    return (
        <div className="tour-extras-panel">
        <button className="add" onClick={() => {
            const newTour = props.getCloned();
            const largestId = newTour.extras.reduce((prev, current) => {
                return current.id > prev ? current.id : prev;
            }, 0);
            newTour.extras.push({
                id: largestId + 1,
                label: "Yeni Ekstra",
                description: "Ekstra açıklaması",
                additionalPrice: {
                    amount: 5,
                    currency: "EUR"
                }
            });
            props.setTour(newTour);
        }}><Plus/> Ekstra Ekle </button>
            {props.tour.extras.map((extra, index) => (
                <TourExtra
                    key={index}
                    extra={extra}
                    onChange={(newExtra) => {
                        const newTour = props.getCloned();
                        newTour.extras[index] = newExtra;
                        props.setTour(newTour);
                    }}
                    onDelete={() => {
                        const newTour = props.getCloned();
                        newTour.extras.splice(index, 1);
                        props.setTour(newTour);
                    }}
                />
            ))}
        </div>
    )
}