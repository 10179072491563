import { useState } from 'react';
import './SelectionCard.css';
import { createClass } from '../../utils/create-class';

export default function SelectionCard(props: {
    items: {[key: string]: {
        title: string;
        description: string;
    }},
    selectedItem?: string,
    onSelectedItemChange: (newItemKey: string) => void
}) {
    const {items, selectedItem = Object.keys(items)[0], onSelectedItemChange} = props;
    return (
        <div className='item-selection'>
            {Object.entries(items).map(([key, item]) => {
                const isSelected = key === selectedItem;
                return (
                    <div
                        className={createClass("item", isSelected && "selected")}
                        onClick={() => onSelectedItemChange(key)}
                    >
                        <div className='title'>{item.title}</div>
                        <div className='content'>
                            <div className='description'>{item.description}</div>
                            <div className='radio'>
                                <div className='inner' />
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}