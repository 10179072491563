import { X } from 'lucide-react';
import { TourTimetableEntry } from '../../common-types';
import TourIncludesIcon from '../tour-includes-panel/TourIncludesIcon';
import './TourTimetableEntryItem.css';

export default function (props: {
    index?: number,
    entry: TourTimetableEntry,
    isPlaceholder?: boolean,
    onTimeChange: (newTime: number) => void,
    onDescriptionChange: (newDescription: string) => void,
    onTimeShowChange: (value: boolean) => void,
    onRemoval: () => void,
    autofocus: boolean,
    autofocusTarget?: string,
}) {
    const padNumber = (num: number) => {
        return num < 10 ? `0${num}` : `${num}`;
    }
    const range = (length: number) => Array.from({ length }, (_, i) => i);
    const hours = Math.floor(props.entry.time / 60);
    const minutes = props.entry.time % 60;
    const paddedHours = padNumber(hours);
    const paddedMinutes = padNumber(minutes);
    const timeInputEntry = `${paddedHours}:${paddedMinutes}`;
    const isPlaceholder = props.isPlaceholder ?? false;
    const className = ["tour-timetable-entry-item", isPlaceholder && "placeholder"].filter(t => !!t).join(" ");
    return (
        <div className={className}>
            {props.entry.showTime !== false && <div className='day-time-selects'>
                <select value={paddedHours} onChange={(e) => {
                    const newTime = parseInt(e.target.value) * 60 + minutes;
                    props.onTimeChange(newTime);
                }}>
                    {range(24).map((hour) => (
                        <option key={hour} value={padNumber(hour)}>{padNumber(hour)}</option>
                    ))}
                </select>
                <span>:</span>
                <select value={paddedMinutes} onChange={(e) => {
                    const newTime = hours * 60 + parseInt(e.target.value);
                    props.onTimeChange(newTime);
                }}>
                    {range(60).map((minute) => (
                        <option key={minute} value={padNumber(minute)}>{padNumber(minute)}</option>
                    ))}
                </select>
            </div>}
            {(props.index !== undefined && props.index !== null) && <div>
                <label htmlFor={`showtime-${props.index}`}>Saati Belli</label>
                <input
                    type='checkbox'
                    id={`showtime-${props.index}`}
                    checked={props.entry.showTime ?? true}
                    onChange={(e) => props.onTimeShowChange(e.target.checked)}
                />
            </div>}
            <input
                type='text'
                onChange={(e) => {
                    props.onDescriptionChange(e.target.value);
                }}
                value={props.entry.description}
                autoFocus={props.autofocus && props.autofocusTarget === "description"}
            />
            {!props.isPlaceholder && <button className='remove-button' onClick={props.onRemoval}>
                <X></X>
                Sil
            </button>}
            
        </div>
    )
}