import { X } from "lucide-react";
import CurrencyInput from "../../components/currency-input/CurrencyInput";
import { TransferPriceApplier, TransferPriceApplierType } from "../../moderator-types";
import { createClass } from "../../utils/create-class";
import { useEffect, useState } from "react";
import "./TransferPricingEntry.css";

export default function (props: {
    entry: TransferPriceApplier,
    onChange: (entry: TransferPriceApplier, changeElement: string, inputElement: HTMLElement) => void,
    canRemove?: boolean,
    onRemove?: () => void,
    autoFocus?: boolean
    autoFocusTarget?: string,
    className?: string
}) {
    const { entry } = props;
    const asExpression: {
        type: TransferPriceApplierType.Expression,
        expression: string
    } = entry as any;
    const asFixed: {
        type: TransferPriceApplierType.Fixed,
        price: number
    } = entry as any;
    let startPrice: number;
    let kmMultiplier: number;
    if(entry.type === TransferPriceApplierType.Expression) {
        const newExpr = entry.expression.replaceAll(" ", "");
        let [startPriceStr, kmMultiplierStr] = newExpr.split("+").map(t => t.trim());
        if(isNaN(parseFloat(startPriceStr)))
        {
            let temp = startPriceStr;
            startPriceStr = kmMultiplierStr;
            kmMultiplierStr = temp;
        }
        let [kmMultipleEntry1, kmMultipleEntry2] = kmMultiplierStr.split("*").map(t => t.trim());
        startPrice = parseInt(startPriceStr);
        kmMultiplier = kmMultipleEntry1 == 'x' ? parseFloat(kmMultipleEntry2) : parseFloat(kmMultipleEntry1);
    }
    const [afterKmStr, setAfterKmStr] = useState(isNaN(entry.afterKm) ? "" : entry.afterKm.toString());
    useEffect(() => {
        setAfterKmStr(isNaN(entry.afterKm) ? "" : entry.afterKm.toString());
    }, [entry.afterKm]);
    const inputs = {
        [TransferPriceApplierType.Expression]: (
            <>
                <div className="price-applier-input-group">
                    <div className="price-applier-input-label">Başlangıç Fiyatı</div>
                    <CurrencyInput
                        value={startPrice}
                        currency="EUR"
                        onChange={(value, elem) => {
                            const newEntry: TransferPriceApplier = {
                                afterKm: entry.afterKm,
                                vehicleId: entry.vehicleId /* FIXTHIS */,
                                type: TransferPriceApplierType.Expression,
                                expression: `${value} + x * ${kmMultiplier}`
                            };
                            props.onChange(newEntry, "start-price", elem);
                        }}
                        autoFocus={props.autoFocus && props.autoFocusTarget === "start-price"}
                    />
                </div>
                <div className="price-applier-input-group">
                    <div className="price-applier-input-label">Km Başı Fiyat</div>
                    <CurrencyInput
                        value={kmMultiplier}
                        currency="EUR"
                        onChange={(value, elem) => {
                            const newEntry: TransferPriceApplier = {
                                afterKm: entry.afterKm,
                                vehicleId: entry.vehicleId /* FIXTHIS */,
                                type: TransferPriceApplierType.Expression,
                                expression: `${startPrice} + x * ${value}`
                            };
                            props.onChange(newEntry, "km-multiplier", elem);
                        }}
                        autoFocus={props.autoFocus && props.autoFocusTarget === "km-multiplier"}
                    />
                </div>
            </>
        ),
        [TransferPriceApplierType.Fixed]: (
            <div className="price-applier-input-group">
                <div className="price-applier-input-label">Fiyat</div>
                <CurrencyInput
                    value={asFixed.price}
                    currency="EUR"
                    onChange={(value, elem) => {
                        const newEntry: TransferPriceApplier = {
                            afterKm: entry.afterKm,
                            vehicleId: entry.vehicleId /* FIXTHIS */,
                            type: TransferPriceApplierType.Fixed,
                            price: value
                        };
                        props.onChange(newEntry, "price", elem);
                    }}
                    autoFocus={props.autoFocus && props.autoFocusTarget === "price"}
                />
            </div>
        )
    }
    return (
        <div className={createClass("transfer-price-applier-entry", props.className, entry.type)}>
            <div className="price-applier-input-group after-km">
                <input min={1} type="number" value={afterKmStr} onChange={(e) => {
                    const value = parseInt(e.target.value);
                    if(isNaN(value)) {
                        setAfterKmStr("");
                        return;
                    }
                    const newEntry: TransferPriceApplier = {
                        ...entry,
                        afterKm: parseInt(e.target.value)
                    };
                    props.onChange(newEntry, "after-km", e.target);
                }}
                autoFocus={props.autoFocus && props.autoFocusTarget === "after-km"}
                />
                <div className="price-applier-input-label">km'den sonra</div>
            </div>
            <div className="price-applier-input-group">
                <div className="price-applier-input-label">Fiyatlama</div>
                <select value={entry.type} onChange={(e) => {
                    const newType = e.target.value as TransferPriceApplierType;
                    if(newType === entry.type) return;
                    let newEntry: TransferPriceApplier = null!
                    if(e.target.value === TransferPriceApplierType.Expression) {
                        newEntry = {
                            afterKm: entry.afterKm,
                            vehicleId: entry.vehicleId /* FIXTHIS */,
                            type: TransferPriceApplierType.Expression,
                            expression: `${asFixed.price} + x * ${1}`
                        }
                    }
                    else {
                        newEntry = {
                            afterKm: entry.afterKm,
                            vehicleId: entry.vehicleId /* FIXTHIS */,
                            type: TransferPriceApplierType.Fixed,
                            price: startPrice
                        }
                    }
                    props.onChange(newEntry, "type", e.target);
                }}>
                    <option value={TransferPriceApplierType.Fixed}>Sabit</option>
                    <option value={TransferPriceApplierType.Expression}>Km Üstünden</option>
                </select>
            </div>
            {inputs[entry.type]}
            {props.canRemove && <div className="pricing-rm-btn"><button onClick={() => {
                props.onRemove();
            }}><X/> Sil </button></div>}
        </div>
    )
}