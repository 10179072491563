import { Ticket } from "../../api/types/ticket";
import { ApiErrorResult, TicketListEntry, TicketType } from "../../common-types";
import { useApi } from "../../hooks/useApi";
import useCommon from "../../hooks/useCommon";
import { usePanels } from "../../hooks/usePanels";
import "./Ticket.css";

export default function(props: {
    assigned: boolean,
    assignee?: string,
    assignedSelf?: boolean,
    ticketListEntry: TicketListEntry,
    refreshHandler: () => void,
    onClick: () => void
}) {
    const typeName = props.ticketListEntry.type === TicketType.Tour ? "Tur" : "Transfer";
    const assignResult = props.assigned && !props.assignedSelf
    const assignedSelf = props.assignedSelf ?? false;
    const isNotAssigned = !assignedSelf && !assignResult;
    const common = useCommon();
    const panels = usePanels();
    const api = useApi();
    const handleAssign = () => {
        common.setLoading(true);
        api.requestAssignment(props.ticketListEntry.id).then((result) => {
            common.setLoading(false);
            const resultAsError = result as ApiErrorResult;
            if(resultAsError.isError) {
                common.setError(resultAsError.message);
                return;
            }
            props.refreshHandler?.();
        })
    }
    const handleRelease = () => {
        common.setLoading(true);
        api.releaseAssignment(props.ticketListEntry.id).then((result) => {
            common.setLoading(false);
            const resultAsError = result as ApiErrorResult;
            if(resultAsError.isError) {
                common.setError(resultAsError.message);
                return;
            }
            props.refreshHandler?.();
        });
    }
    return (
            <div onClick={props.onClick} className={`ticket-container ${assignResult ? "assigned" : ""} ${assignedSelf ? "self" : ""}`}>
                <i className={`ticket-icon ${props.ticketListEntry.type}`}>
                </i>
                <span className="ticket-title">{props.ticketListEntry.displayName}</span>
                <span className="ticket-date">{new Date(props.ticketListEntry.creationDate).toLocaleString()}</span>
                <div className="ticket-buttons">
                {assignResult && <div className="right-align ticket-assignee"><strong>{props.assignee}</strong> bakıyor</div>}
                {assignedSelf && <div className="assigned-self right-align" onClick={(e) => {
                    handleRelease();
                    e.preventDefault();
                    e.stopPropagation();
                }}>
                    <span className="label">Senin!</span>
                    <span className="removeLabel">Bırak</span>
                </div>}
                {isNotAssigned && <button onClick={(e) => {
                    handleAssign()
                    e.preventDefault();
                    e.stopPropagation();
                }} className="request-assign right-align">Kendine Ata</button>}
                </div>
            </div>
    )
}