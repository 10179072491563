import { Delete } from 'lucide-react';
import { TourExtra } from '../../common-types';
import InputGroup from '../../components/input-group/InputGroup';
import PriceInput from '../../components/price-input/PriceInput';
import './TourExtra.css';

export default function (props: {
    extra: TourExtra,
    onChange: (extra: TourExtra) => void,
    onDelete: () => void
}) {
    return (
        <div className='tour-extra'>
            <InputGroup title='Ekstra İsmi'>
                <input className='name' type='text' value={props.extra.label} onChange={(e) => {
                    const val = e.target.value ?? "";
                    const newExtra = {...props.extra};
                    newExtra.label = val;
                    props.onChange(newExtra);
                }} />
            </InputGroup>
            <InputGroup title='Ekstra Açıklaması'>
                <textarea className='description' value={props.extra.description} onChange={(e) => {
                    const val = e.target.value ?? "";
                    const newExtra = {...props.extra};
                    newExtra.description = val;
                    props.onChange(newExtra);
                }}/>
            </InputGroup>
            <PriceInput label='Ek Ücret' price={props.extra.additionalPrice} onAmountChange={(newAmount) => {
                const newExtra = {...props.extra};
                newExtra.additionalPrice.amount = newAmount;
                props.onChange(newExtra);
            }} />
            <button onClick={props.onDelete} className='delete'>Sil <Delete/></button>
        </div>
    )
}