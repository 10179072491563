import Sidebar from "../../components/bars/Sidebar";
import SidebarButton from "../../components/bars/SidebarButton";
import Discount from "../../components/discount/Discount";
import PanelContainer from "../../components/slider/PanelContainer";
import { PanelsContext } from "../../context/PanelsContext";
import { PanelsHook } from "../../hooks/usePanels";
import Discounts from "../discounts/Discounts";
import TransferPricing from "../transfer-pricing/TransferPricing";
import TransferPricingCars from "../transfer-pricing/TransferPricingCars";
import "./Prices.css";

export default function(props: {
    innerPanels: PanelsHook
}) {
    return (
    <div className="prices-container">
        <Sidebar>
            <SidebarButton
                label="İndirim"
                key={"discounts"}
                enabled={props.innerPanels.current === "discounts"}
                onClick={() => props.innerPanels.handleChange("discounts")}
            />
            <SidebarButton
                label="Transfer Fiyatlaması"
                key={"transfer-prices"}
                enabled={props.innerPanels.current === "transfer-prices"}
                onClick={() => props.innerPanels.handleChange("transfer-prices")}
            />
        </Sidebar>
        <PanelsContext.Provider value={props.innerPanels}>
            <PanelContainer
                className="prices-inner-panel"
                currentPanelKey={props.innerPanels.current}
                panelsInner={
                    [
                        {
                            component: <><Discounts /></>,
                            key: "discounts"
                        },
                        {
                            component: <TransferPricingCars />,
                            key: "transfer-prices"
                        }
                    ]
                }
                onPanelChange={(npk) => {}}
            />
        </PanelsContext.Provider>
    </div>)
}