import { createClass } from "../../utils/create-class";
import "./InputGroup.css"

export default function InputGroup(props: {
    title: string,
    children?: React.ReactNode,
    isHorizontal?: boolean
}) {
    const isHorizontal = props.isHorizontal ?? false;
    return (
        <div className={createClass('input-group', isHorizontal && "horizontal")}>
            <div className='title'>{props.title}</div>
            <div className='content'>
                {props.children}
            </div>
        </div>
    )
}