import { useEffect, useState } from 'react';
import { Price } from '../../common-types';
import './PriceInput.css';
import NumberInput from '../number-input/NumberInput';

export default function PriceInput(props: {
    label: string,
    price: Price,
    onAmountChange: (newAmount: number) => void
}) {
    const amountMap = {
        "EUR": "€",
        "USD": "$",
        "TRY": "₺",
        "GBP": "£"
    };
    return (
        <NumberInput
            label={props.label}
            amount={props.price.amount}
            unit={amountMap[props.price.currency]}
            onAmountChange={props.onAmountChange}
        />
    );
}