import { useState } from "react";
import { Discount, DiscountTarget } from "../../moderator-types";
import "./Discount.css";

export default function (props: {
    discountAmount?: number;
    target?: DiscountTarget;
    description?: string;
    enableToggle?: boolean;
    label?: string;
    applyLabel?: string;
    children?: {
        title?: React.ReactNode;
        contentBeforeToggle?: React.ReactNode;
        contentToggleBeforeDiscount?: React.ReactNode;
        contentToggleAfterDiscount?: React.ReactNode;
    };
    onSave?: (discount: Discount) => void;
    onDisable: (target: DiscountTarget) => void;
    onChange?: (percentage: number) => void;
}) {
    const enableToggle = props.enableToggle ?? true;
    const hasSaveEvent = !!props.onSave;
    const isEnabled = !!props.discountAmount;
    const givenAmount = props.discountAmount ?? 0;
    const [enabled, setEnabled] = useState(isEnabled);
    const [discountAmount, setDiscountAmount] = useState(givenAmount);
    const hasChange = isEnabled !== enabled || givenAmount !== discountAmount;
    let buttonPrompt = hasChange ? "Değişiklikleri Kaydet" : "Değişiklik Yok";
    let buttonEnabled = hasChange;
    if(buttonEnabled && discountAmount == 0)
    {
        buttonPrompt = "İndirim 0 olamaz";
        buttonEnabled = false;
    }
    if(buttonEnabled && discountAmount == 100)
    {
        buttonPrompt = "İndirim 100 olamaz";
        buttonEnabled = false;
    }
    if(buttonEnabled && isNaN(discountAmount))
    {
        buttonPrompt = "Sayı giriniz";
        buttonEnabled = false;
    }
    
    return (
        <div className="discount-container">
            <div className="discount-heading">
                {(props.label || props.description) && <div className="discount-title">
                    {props.label}{props.description && <><br/><span className="discount-description">{props.description}</span></>}
                </div>}
                {props.children?.title && <div className="discount-heading-content">
                    {props.children?.title}
                </div>}
                {props.applyLabel && <div className="discount-apply">
                    {props.applyLabel}
                </div>}
                {enableToggle && <div className={`discount-toggle ${enabled ? "enabled" : ""}`}
                    onClick={() => {
                        const newState = !enabled;
                        setEnabled(newState);
                        if(newState === false) {
                            props.onDisable(props.target);
                        }
                    }}
                >
                    <div className="discount-toggle-circle"></div>
                </div>}
            </div>
            {props.children?.contentBeforeToggle}
            {enabled &&
            <div className={`discount-input-container ${enabled ? "enabled" : ""}`}>
                {props.children?.contentToggleBeforeDiscount}
                <div className="discount-input">
                    <div className="discount-percent">%</div>
                    <input
                        type="number"
                        value={discountAmount}
                        min={0}
                        max={99}
                        step={1}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setDiscountAmount(value);
                            props.onChange?.(value);
                        }}
                    />
                </div>
                {props.children?.contentToggleAfterDiscount}
                {hasSaveEvent && <button onClick={() => {
                    props.onSave({
                        target: props.target,
                        percentage: discountAmount
                    })
                }} className={buttonEnabled ? "enabled" : ""}>
                    {buttonPrompt}
                </button>}
            </div>
            }
        </div>
    )
}