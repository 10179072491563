import React, { useState } from 'react';
import { TransferPriceApplier, TransferVehicle } from '../../moderator-types';
import './TransferPricingCar.css';
import { CarFront, ChevronsDownUp, ChevronsUpDown, Delete, FerrisWheel, Plus } from 'lucide-react';
import { createClass } from '../../utils/create-class';
import InputGroup from '../../components/input-group/InputGroup';
import SelectionCard from '../../components/selection-card/SelectionCard';
import { DiscountType } from '../../common-types';
import PriceInput from '../../components/price-input/PriceInput';
import Discount from '../../components/discount/Discount';
import NumberInput from '../../components/number-input/NumberInput';
import TransferPricing from './TransferPricing';

export default function TransferPricingCar(props: {
    vehicle: TransferVehicle,
    isPlaceholder?: boolean,
    appliers: TransferPriceApplier[],
    onClick?: () => void,
    onAppliersChange: (newAppliers: TransferPriceApplier[]) => void,
    onVehicleChange: (newVehicle: TransferVehicle) => void,
    onRemove: () => void
}) {
    const [folded, setFold] = useState(true);
    const isPlaceholder = props.isPlaceholder ?? false;
    const finalFolded = isPlaceholder || folded;
    const foldIcon = isPlaceholder ? <Plus /> : (finalFolded ? <ChevronsDownUp /> : <ChevronsUpDown />);
    const isSingleDiscount = props.vehicle.discountType === DiscountType.Single;
    return (
        <div className={createClass("transfer-pricing-car", !finalFolded && "open", isPlaceholder && "placeholder")}>
            <div className='list-item' onClick={() => {
                const newFolded = !folded;
                if(isPlaceholder)
                {
                    props.onClick?.();
                    return;
                }
                setFold(newFolded);
            }}>
                <div className='icon'>
                    <CarFront />
                </div>
                <div className='title'>
                    {isPlaceholder ? "Araç Ekle" : props.vehicle.name}
                </div>
                <div className='spacing' />
                {!isPlaceholder && <div className='capacity'>
                    <div className='label'>Kapasite</div>
                    <div className='number'>{props.vehicle.maxPassengers}</div>
                    <div className='unit'>kişi</div>
                </div>}
                {!isPlaceholder && <button className='remove' onClick={props.onRemove}>Sil <Delete /></button>}
                <div className='fold'>
                    {foldIcon}
                </div>
            </div>
            {!finalFolded && <>
            <div className='hline'></div>
            <div className='properties'>
                <InputGroup title='Araç Adı'>
                    <input type='text' value={props.vehicle.name} onChange={(e) => {
                        props.onVehicleChange({...props.vehicle, name: e.target.value});
                    }} />
                </InputGroup>
                <NumberInput
                    label='Kapasite'
                    amount={props.vehicle.maxPassengers}
                    onAmountChange={(newAmount) => {
                        props.onVehicleChange({...props.vehicle, maxPassengers: newAmount});
                    }}
                />
                <div className='hline'></div>
                {isSingleDiscount && <InputGroup title='İndirim Oranları'>
                    <Discount
                        label='Tek Yön İndirimi'
                        onChange={(nd) => {
                            props.onVehicleChange({...props.vehicle, discountAmount: {
                                ...props.vehicle.discountAmount,
                                oneWay: nd
                            }});
                        }}
                        onDisable={() => {
                            props.onVehicleChange({...props.vehicle, discountAmount: {
                                ...props.vehicle.discountAmount,
                                oneWay: undefined
                            }});
                        }}
                        discountAmount={props.vehicle.discountAmount.oneWay ?? 0}
                    />
                    <Discount
                        label='Round Trip İndirimi'
                        onChange={(nd) => {
                            props.onVehicleChange({...props.vehicle, discountAmount: {
                                ...props.vehicle.discountAmount,
                                roundTrip: nd
                            }});
                        }}
                        onDisable={() => {
                            props.onVehicleChange({...props.vehicle, discountAmount: {
                                ...props.vehicle.discountAmount,
                                roundTrip: undefined
                            }});
                        }}
                        discountAmount={props.vehicle.discountAmount.roundTrip ?? 0}
                    />
                </InputGroup>}
                {isSingleDiscount && <div className='hline'></div>}
                <InputGroup title="İndirim Tipi">
                <SelectionCard
                    selectedItem={props.vehicle.discountType}
                    onSelectedItemChange={(newItem) => {
                        props.onVehicleChange({...props.vehicle, discountType: newItem as DiscountType});
                    }}
                    items={{
                        [DiscountType.None]: {
                            title: 'İndirim Yok',
                            description: 'İndirim uygulanmaz.'
                        },
                        [DiscountType.Single]: {
                            title: "Araca Özel İndirim",
                            description: "Araca özel indirim uygulanır."
                        },
                        [DiscountType.UseGeneral]: {
                            title: "Genel Transfer İndirimi",
                            description: "Genel transfer indirimi uygulanır."
                        }
                    }}
                />
                </InputGroup>
            </div>
            <div className='hline'></div>
            <TransferPricing
                appliers={props.appliers}
                onNewAppliers={(newAppliers) => {
                    props.onAppliersChange(newAppliers);
                }}
            />
            </>}
        </div>
    )
}